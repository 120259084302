const femRoutes = {
  home: '/',
  personalBanking: '/personal-banking',
  about: '/about',
  datePicker: '/public/date-picker',
  ecomLp: '/e',
  api: '/api',
  bankAccounts: '/bank-accounts',
  contact: '/contact',
  credit: '/credit',
  treasuryContact: '/contact/treasury',
  contactPartnerships: '/contact/partnerships',
  contactSales: '/contact/sales',
  ecommerce: '/ecommerce',
  faq: '/faq',
  financialWorkflows: '/financial-workflows',
  accountingAutomations: '/accounting-automations',
  billPay: '/bill-pay',
  howItWorks: '/how-mercury-works',
  investorIndex: '/investor-database',
  investorDetails: (investorCallsign: string) =>
    `/investor-database/${investorCallsign}`,
  investorFundDetails: (fundCallsign: string) =>
    `/investor-database/fund/${fundCallsign}`,
  jobs: '/jobs',
  job: (job: string): string => `/jobs/${job}`,
  lifeScience: '/life-science',
  northstar: '/northstar',
  partnerships: '/partnerships',
  perks: '/perks',
  // TODO: update once perks goes live
  perksDetails: (slug: string) => `/perks-new/${slug}`,
  pricing: '/pricing',
  raise: '/raise',
  raiseFounderCommunity: '/raise/founder-community',
  raiseInvestorConnect: '/raise/investor-connect',
  safe: '/safe',
  softwareStackIndex: '/raise/software-stack',
  softwareStackCategory: (categoryId: string) =>
    `/raise/software-stack/categories/${categoryId}`,
  softwareStackTool: (toolId: string) => `/raise/software-stack/tools/${toolId}`,
  saas: '/saas',
  security: '/security',
  treasury: '/treasury',
  vcFunds: '/vc-funds',
  ventureDebt: '/venture-debt',
  web3: '/web3',
  ycInterviews: '/ycmocks',
} as const

const legalRoutes = {
  terms: '/legal/terms',
  payment: '/legal/payment',
  privacy: '/legal/privacy',
  esign: '/legal/esign',
  // bbva page is only available to certain logged-in orgs, otherwise user gets redirected,
  // so we don't want this to be included on our sitemap
  bbva: '/legal/bbva',
  referralAgreement: '/legal/referrals',
  choice: '/legal/choice',
  choicePersonalBanking: '/legal/choice-personal',
  evolve: '/legal/evolve',
  ioChargeCardAgreement: '/legal/io-charge-card-agreement',
  // https://mercurytechnologies.slack.com/archives/C04TZE0B2E6/p1680807487940479?thread_ts=1680276390.559059&cid=C04TZE0B2E6
  io2percentCashbackPromotion: '/legal/io-2percent-cashback-promotion',
  cashbackDisclosure: '/legal/cashback-disclosure',
  patriotAct: '/legal/patriot-act',
  financialWorkflows: '/legal/financial-workflows',
} as const

export const internalLinks = {
  ...femRoutes,
  legal: legalRoutes,
} as const
